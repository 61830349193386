import React, { useState } from 'react'
import axios from 'axios';
import CertificateInformation from './CertificateInformation';


// css
import "./CheckCertificates.css";
import ComponentContainer from '../../components/component-container/ComponentContainer';
import Spinner from '../../components/spinner/spinner';
import endpointConfig from '../../config/endpointConfig';
import notifyHB from '../../config/notifyHB';

interface CheckCertificatesProps {
    refreshToken: Function
}

const CheckCertificates: React.FC<CheckCertificatesProps> = ({ refreshToken }) => {

    type CheckResponse = {
        caSerial: string,
        certType: string,
        certificateStatus: string,
        createdAt: string,
        expirationDate: string,
        emailDeclared: string,
        serial: string,
        revocationTimeStamp: string,
        suscriberName: string
    }

    // useStates
    const [certificateSerial, setCertificateSerial] = useState("");
    const [publicKeyHashHex, setPublicKeyHashHex] = useState("");
    const [serialError, setSerialError] = useState("")
    const [caHashError, setCaHashError] = useState("")
    const [certResult, setCertResult] = useState(<div></div>)

    const certCheckRequest = async () => {

        if (certificateSerial !== "" && publicKeyHashHex !== "") {
            setCertResult(
                <Spinner/>
            )

            try {
                const checkResponse = await axios.post(
                    endpointConfig.certcheck,
                    {
                        "publicKeyHashHex": publicKeyHashHex,
                        "certificate_serial": certificateSerial
                    }
                );
    
                let certificateInformation: CheckResponse = JSON.parse(checkResponse.data.certificateInformation)
                setCertResult(
                    <CertificateInformation
    
                        caSerial={certificateInformation["caSerial"]}
                        certType={certificateInformation["certType"]}
                        certificateStatus={returnCertificateStatus(certificateInformation["certificateStatus"])}
                        createdAt={certificateInformation["createdAt"]}
                        expirationDate={certificateInformation["expirationDate"]}
                        emailDeclared={certificateInformation["emailDeclared"]}
                        serial={certificateInformation["serial"]}
                        revocationTimeStamp={certificateInformation["revocationTimeStamp"]}
                        suscriberName={certificateInformation["suscriberName"]}
    
                    />
                )
            } catch (error: any) {
                setCertResult(
                    <div></div>
                )
                alert("Hubo un error en la validación del documento")

                notifyHB("check-certificates", "CheckCertificates", error)
                
            }

        } else {

            setSerialError("")
            setCaHashError("")

            if (certificateSerial === ""){
                setSerialError("Llenar campo")
            }

            if (publicKeyHashHex === ""){
                setCaHashError("Llenar campo")
            }

        }
    }

    const returnCertificateStatus = (certStatus: string) => {
        const statuses: any = {
            "0": "unspecified",
            "1": "Key Compromise",
            "2": "cA Compromise",
            "3": "Affiliation Changed",
            "4": "Superseded",
            "5": "Cessation Of Operation",
            "6": "Suspended",
            "8": "Remove From CRL",
            "9": "privilegeWithdrawn",
            "10": "aACompromise"
        }

        if (statuses.hasOwnProperty(certStatus)) {
            return statuses[certStatus];
        } else {
            return certStatus;
        }
    }

    return (
        <ComponentContainer>
            <h1>Consultar Certificados</h1>

            <br />

            <div className="certSerial">
                <label style={{
                    position: "absolute",
                    color: "red",
                    margin: "-5px 0px 0px 12px"
                }} > {serialError} </label>
                <input
                    className="inputLayout"
                    type="text"
                    placeholder="Ingresar número de serie"
                    onChange={event => setCertificateSerial(event.target.value)}
                />

            </div>

            <div className="caHash">
                <label style={{
                    position: "absolute",
                    color: "red",
                    margin: "-5px 0px 0px 12px"
                }} > {caHashError} </label>
                <input
                    className="inputLayout"
                    type="text"
                    placeholder="Ingresar ca hash"
                    onChange={event => setPublicKeyHashHex(event.target.value)}
                />
            </div>

            <br />

            <button
                className="cyber-button inputLayout"
                onClick={certCheckRequest}
            > Buscar </button>

            {certResult}
        </ComponentContainer>
    )
}

export default CheckCertificates
