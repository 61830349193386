import React from 'react'
import { Link } from 'react-router-dom';
import ComponentContainer from '../../components/component-container/ComponentContainer';

interface CertificatesAndCrlProps {
    refreshToken: Function
}

const CertificatesAndCrl: React.FC<CertificatesAndCrlProps> = ({refreshToken}) => {


    const downloadFile = (url: string) => {
        window.open(url, '_blank');
    };

    return (

        <ComponentContainer>
            <h1>Certificados y CRL</h1>
            <div className="cyber-spacer large"></div>
            <div className="cyber-grid two-columns">
                <div className="cyber-card bordered flex direction-column justify-between align-start">
                    <h5>Certificado de entidad raiz</h5>
                    <div className="cyber-spacer small"></div>
                    <p>Este es el certificado de la entidad raiz de CyberSign</p>
                    <Link to="#" className="cyber-button icon-left" onClick={() => downloadFile('https://root-ca-storage-us-east-1-206515832641.s3.amazonaws.com/rootCA.crt')}>
                        Descargar
                    </Link>
                </div>
                <div className="cyber-card bordered flex direction-column justify-between align-start">
                    <h5>Certificado de entidad intermedia</h5>
                    <div className="cyber-spacer small"></div>
                    <p>Este es el certificado de la entidad intermedia de CyberSign</p>
                    <Link to="#" className="cyber-button icon-left" onClick={() => downloadFile('https://issuing-ca-storage-us-east-1-206515832641.s3.amazonaws.com/56495513335055711632368664008751437771.crt')}>
                        Descargar
                    </Link>
                </div>
                <div className="cyber-card bordered flex direction-column justify-between align-start">
                    <h5>CRL de entidad raiz</h5>
                    <div className="cyber-spacer small"></div>
                    <p>Este es el CRL de la entidad raiz de CyberSign</p>
                    <Link to="#" className="cyber-button icon-left" onClick={() => downloadFile('https://crl-root.cybersign.gt/root.crl')}>
                        Descargar
                    </Link>
                </div>
                <div className="cyber-card bordered flex direction-column justify-between align-start">
                    <h5>CRL de entidad intermedia</h5>
                    <div className="cyber-spacer small"></div>
                    <p>Este es el CRL de la entidad intermedia de CyberSign</p>
                    <Link to="#" className="cyber-button icon-left" onClick={() => downloadFile('https://crl-storage-us-east-1-206515832641.s3.amazonaws.com/56495513335055711632368664008751437771.crl')}>
                        Descargar
                    </Link>
                </div>
            </div>
        </ComponentContainer>
    )
}

export default CertificatesAndCrl
